import login from "app/auth/mutations/login"
import { Login } from "app/auth/validations"
import { FORM_ERROR, Form } from "app/core/components/Form"
import { LabeledTextField } from "app/core/components/LabeledTextField"
import { TGoogleButton, TMicrosoftButton } from "app/core/components/button/TIconButton"
import { DottedLink } from "app/core/components/links/TDottedLink"
import { theme } from "app/core/styles/styles"
import { AuthenticationError, useMutation } from "blitz"
import { browserEnv } from "browser.config"
import { TAuthLayout } from "./TAuthTemplate"

type LoginFormProps = {
  onSuccess?: () => void
}

export const LoginForm = (props: LoginFormProps) => {
  const [loginMutation] = useMutation(login)

  return (
    <TAuthLayout>
      <div>
        <div style={{ paddingTop: 32, paddingBottom: 32 }}>
          {browserEnv.NEXT_PUBLIC_MICROSOFT_ENABLED || browserEnv.NEXT_PUBLIC_GOOGLE_ENABLED ? (
            <h4 style={{ color: theme.gray[600], fontWeight: 300 }}>
              Sign in to your company account (SSO)
            </h4>
          ) : null}

          {browserEnv.NEXT_PUBLIC_MICROSOFT_ENABLED ? <TMicrosoftButton /> : null}
          {browserEnv.NEXT_PUBLIC_GOOGLE_ENABLED ? <TGoogleButton /> : null}
        </div>
        {browserEnv.NEXT_PUBLIC_USERNAME_PASSWORD_ENABLED ? (
          <>
            <h4 style={{ color: theme.gray[600], fontWeight: 300 }}>Sign in with your email</h4>
            <Form
              initialValues={{ email: "", password: "" }}
              schema={Login}
              submitText="Login"
              onSubmit={async (values) => {
                try {
                  await loginMutation(values)
                  props.onSuccess?.()
                } catch (error) {
                  if (error instanceof AuthenticationError) {
                    return { [FORM_ERROR]: "Sorry, those credentials are invalid" }
                  } else {
                    return {
                      [FORM_ERROR]:
                        "Sorry, we had an unexpected error. Please try again. - " +
                        error.toString(),
                    }
                  }
                }
              }}
            >
              <LabeledTextField label="Email" name="email" placeholder="Enter your email..." />
              <LabeledTextField
                label="Password"
                name="password"
                placeholder="Enter your password..."
                type="password"
              />
            </Form>

            <div style={{ paddingTop: 32, paddingBottom: 32 }}>
              <DottedLink
                link={"/forgot-password"}
                style={{ fontWeight: 300, color: theme.gray[600] }}
                text={"Forgot your password?"}
              />
            </div>
          </>
        ) : null}

        <div style={{ textAlign: "center" }}>
          <p style={{ color: theme.gray[600], fontWeight: 300 }}>
            By signing in, you acknowledge that you have read, understood, and agree to Evatir’s{" "}
            <a href="https://evatir.com/terms-of-service" rel="noopener noreferrer" target="_blank">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="https://evatir.com/privacy-policy" rel="noopener noreferrer" target="_blank">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </TAuthLayout>
  )
}

export default LoginForm
