import { GoogleLogo, MicrosoftLogo } from "app/pages"
import { useRouter } from "blitz"
import React, { ReactNode } from "react"
import { TButton } from "./TButton"

export const TGoogleButton = (props: any) => {
  const router = useRouter()
  return (
    <TIconButton
      logo={<GoogleLogo />}
      text={"Sign in with Google"}
      onClick={() => router.push("/api/auth/google")}
    />
  )
}

export const TMicrosoftButton = (props: any) => {
  const router = useRouter()
  return (
    <TIconButton
      logo={<MicrosoftLogo />}
      text={"Sign in with Microsoft"}
      onClick={() => window.open("/api/auth/azuread-openidconnect", "_self")}
    />
  )
}

export const TIconButton = (
  props: { text: string; logo: ReactNode; onClick: () => void; style?: React.CSSProperties } & {
    [k: string]: any
  }
) => {
  return (
    <TButton
      block
      style={{ width: "100%", ...props.style }}
      onClick={() => {
        props.onClick()
      }}
    >
      <div style={{ display: "flex", placeContent: "center" }}>
        {props.logo}
        <span style={{ marginLeft: 10 }}>{props.text}</span>
      </div>
    </TButton>
  )
}
