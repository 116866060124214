import { Button } from "antd"
import React, { ReactNode } from "react"

export const TButton = (
  props: {
    children?: string | ReactNode
    text?: string
    onClick: () => void
    style?: React.CSSProperties
  } & {
    [k: string]: any
  }
) => {
  return (
    <Button
      {...props}
      style={{
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.text}
      {props.children}
    </Button>
  )
}
