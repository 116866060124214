import { BlitzPage } from "blitz"
import React from "react"

const Home: BlitzPage = () => {
  return <div />
}

export const EvatirLogoHalf = (props: { style: React.CSSProperties }) => {
  return (
    <svg
      fill="none"
      height="82"
      style={{ ...props.style }}
      viewBox="0 0 51 82"
      width="51"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.3075 29.8466C50.9087 27.9123 50.9542 26.7862 50.9542 13.8828V0L46.1241 0.0108029C42.4127 0.0189894 40.9139 0.152929 39.6525 0.58911C37.2018 1.43626 35.2268 2.70255 33.5358 4.51048C30.551 7.70128 29.6691 10.5509 29.6691 17.0032V21.4253L29.5054 39.7868L35.0722 39.6224C40.294 39.4682 40.7596 39.4014 42.581 38.5493C46.4506 36.7385 49.0893 33.7664 50.3075 29.8466Z"
        fill="currentColor"
      />
      <path
        d="M21.5992 47.5309L21.7727 40.4904L21.9365 22.1289L12.1597 31.9646C1.70579 42.4821 1.06167 43.3128 0.316032 47.244C0.134617 48.2006 -0.00194385 55.9601 2.09341e-05 65.1766L0.00362855 81.4234L8.59757 72.7456C17.8435 63.4096 18.6756 62.3309 20.4092 57.4403C21.3948 54.6588 21.4312 54.3569 21.5992 47.5309Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const EvatirLogo = (props: { style: any }) => {
  return (
    <svg
      fill="none"
      height="33"
      style={{ ...props.style }}
      viewBox="0 0 105 33"
      width="105"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.8813 18.0188H36.6353C37.0882 19.7219 38.3656 20.5735 40.4673 20.5735C41.8081 20.5735 42.8227 20.1205 43.5112 19.2146L46.3377 20.8452C44.9969 22.7839 43.022 23.7532 40.413 23.7532C38.1663 23.7532 36.3635 23.0738 35.0046 21.7149C33.6457 20.356 32.9663 18.6438 32.9663 16.5783C32.9663 14.5491 33.6367 12.8459 34.9775 11.4689C36.3001 10.0919 38.0213 9.40345 40.1412 9.40345C42.1523 9.40345 43.7921 10.0919 45.0604 11.4689C46.3649 12.8459 47.0171 14.5491 47.0171 16.5783C47.0171 16.9588 46.9718 17.439 46.8813 18.0188ZM36.5809 15.301H43.5112C43.3119 14.4132 42.9043 13.7337 42.2882 13.2627C41.6903 12.7916 40.9747 12.5561 40.1412 12.5561C39.199 12.5561 38.42 12.8006 37.8039 13.2898C37.1879 13.7609 36.7802 14.4313 36.5809 15.301Z"
        fill="currentColor"
      />
      <path
        d="M54.5596 19.2961L57.8752 9.78393H61.7345L56.5707 23.3727H52.5756L47.4119 9.78393H51.2711L54.5596 19.2961Z"
        fill="currentColor"
      />
      <path
        d="M73.2217 11.3874V9.78393H76.7276V23.3727H73.2217V21.7693C72.1708 23.0919 70.6942 23.7532 68.7917 23.7532C66.9799 23.7532 65.4217 23.0647 64.1172 21.6877C62.8308 20.2926 62.1876 18.5895 62.1876 16.5783C62.1876 14.5853 62.8308 12.8912 64.1172 11.4961C65.4217 10.101 66.9799 9.40345 68.7917 9.40345C70.6942 9.40345 72.1708 10.0648 73.2217 11.3874ZM66.7534 19.3505C67.46 20.0571 68.3569 20.4104 69.444 20.4104C70.5311 20.4104 71.428 20.0571 72.1346 19.3505C72.8593 18.6257 73.2217 17.7017 73.2217 16.5783C73.2217 15.455 72.8593 14.54 72.1346 13.8334C71.428 13.1087 70.5311 12.7463 69.444 12.7463C68.3569 12.7463 67.46 13.1087 66.7534 13.8334C66.0468 14.54 65.6935 15.455 65.6935 16.5783C65.6935 17.7017 66.0468 18.6257 66.7534 19.3505Z"
        fill="currentColor"
      />
      <path
        d="M87.6796 9.78393V13.154H84.6085V18.8069C84.6085 19.278 84.7262 19.6222 84.9618 19.8397C85.1973 20.0571 85.5416 20.1839 85.9945 20.2201C86.4475 20.2383 87.0092 20.2292 87.6796 20.193V23.3727C85.306 23.6445 83.612 23.4271 82.5973 22.7205C81.6008 21.9957 81.1026 20.6912 81.1026 18.8069V13.154H78.7381V9.78393H81.1026V7.03899L84.6085 5.97906V9.78393H87.6796Z"
        fill="currentColor"
      />
      <path
        d="M91.8908 8.15328C91.311 8.15328 90.8037 7.94491 90.3689 7.52819C89.9521 7.09335 89.7438 6.58603 89.7438 6.00624C89.7438 5.42645 89.9521 4.91914 90.3689 4.48429C90.8037 4.04945 91.311 3.83203 91.8908 3.83203C92.4887 3.83203 92.996 4.04945 93.4128 4.48429C93.8476 4.91914 94.065 5.42645 94.065 6.00624C94.065 6.58603 93.8476 7.09335 93.4128 7.52819C92.996 7.94491 92.4887 8.15328 91.8908 8.15328ZM93.6574 23.3727H90.1514V9.78393H93.6574V23.3727Z"
        fill="currentColor"
      />
      <path
        d="M100.372 9.78393V12.1212C100.68 11.2696 101.206 10.6264 101.948 10.1916C102.691 9.73864 103.543 9.51216 104.503 9.51216V13.4257C103.434 13.2808 102.474 13.4982 101.622 14.078C100.789 14.6397 100.372 15.5728 100.372 16.8773V23.3727H96.8662V9.78393H100.372Z"
        fill="currentColor"
      />
      <path
        d="M20.3889 12.0965C20.6325 11.3126 20.651 10.8561 20.651 5.62655V0L18.6934 0.00437828C17.1892 0.00769621 16.5818 0.0619805 16.0705 0.23876C15.0773 0.5821 14.2768 1.09531 13.5915 1.82805C12.3818 3.12124 12.0244 4.27615 12.0244 6.89121V8.68343L11.958 16.1252L14.2142 16.0585C16.3305 15.996 16.5192 15.9689 17.2574 15.6236C18.8257 14.8897 19.8952 13.6851 20.3889 12.0965Z"
        fill="currentColor"
      />
      <path
        d="M8.75391 19.2638L8.82425 16.4103L8.89061 8.96863L4.9282 12.9549C0.691336 17.2175 0.430281 17.5542 0.128084 19.1475C0.0545588 19.5352 -0.000787819 22.68 8.48437e-06 26.4154L0.00147061 33L3.4845 29.483C7.23177 25.6992 7.56901 25.2621 8.27161 23.2799C8.67109 22.1526 8.68582 22.0303 8.75391 19.2638Z"
        fill="#ECBB44"
      />
    </svg>
  )
}

export const MicrosoftLogo = () => {
  return (
    <svg
      fill="none"
      height="17"
      style={{ placeSelf: "center" }}
      viewBox="0 0 16 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H7.57886V7.57886H0V0Z" fill="#F25022" />
      <path d="M0 8.42114H7.57886V16H0V8.42114Z" fill="#00A4EF" />
      <path d="M8.42114 0H16V7.57886H8.42114V0Z" fill="#7FBA00" />
      <path d="M8.42114 8.42114H16V16H8.42114V8.42114Z" fill="#FFB900" />
    </svg>
  )
}

export const GoogleLogo = () => {
  return (
    <svg
      fill="none"
      height="18"
      style={{ placeSelf: "center" }}
      viewBox="0 0 17 16"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8.18371C17 7.63989 16.9551 7.09314 16.8591 6.55814H9.16046V9.63879H13.5691C13.3862 10.6324 12.7983 11.5113 11.9376 12.0698V14.0687H14.5678C16.1123 12.6754 17 10.6177 17 8.18371Z"
        fill="#4285F4"
      />
      <path
        d="M9.16042 16C11.3617 16 13.2182 15.2916 14.5707 14.0687L11.9406 12.0698C11.2088 12.5578 10.2641 12.8341 9.16342 12.8341C7.03409 12.8341 5.22865 11.4261 4.58085 9.53299H1.8667V11.5936C3.25227 14.2951 6.07438 16 9.16042 16V16Z"
        fill="#34A853"
      />
      <path
        d="M4.57786 9.53298C4.23596 8.53941 4.23596 7.46353 4.57786 6.46996V4.40933H1.8667C0.709065 6.66985 0.709065 9.33309 1.8667 11.5936L4.57786 9.53298V9.53298Z"
        fill="#FBBC04"
      />
      <path
        d="M9.16042 3.16589C10.3241 3.14825 11.4487 3.57743 12.2914 4.36523L14.6217 2.0812C13.1462 0.72312 11.1878 -0.0235267 9.16042 -1.02057e-05C6.07438 -1.02057e-05 3.25227 1.70493 1.8667 4.40932L4.57785 6.46995C5.22265 4.57394 7.03109 3.16589 9.16042 3.16589V3.16589Z"
        fill="#EA4335"
      />
    </svg>
  )
}

Home.suppressFirstRenderFlicker = true
export default Home
