export const DottedStyle = {
  paddingBottom: 4,
  color: "#000000",
  textDecoration: "none",
  borderBottom: "1px dashed #8A8A8A",
}

export const DottedLink = (props: { text: string; link: string; style: any }) => {
  return (
    <a
      href={props.link}
      rel="noreferrer"
      style={{
        ...props.style,
        height: 25,
        ...DottedStyle,
      }}
      target="_blank"
    >
      {props.text}
    </a>
  )
}
