import { bool, envsafe, num, str } from "envsafe"

export const browserEnv = envsafe({
  NEXT_PUBLIC_SHARING_URL: str({
    // because of how nextjs deals with transpiling public env vars
    // we have to put it in as `input`
    input: process.env.NEXT_PUBLIC_SHARING_URL,
  }),
  NEXT_PUBLIC_BROWSER_ENV: str({
    input: process.env.NEXT_PUBLIC_BROWSER_ENV,
    default: "production",
    allowEmpty: true,
    choices: ["production", "staging", "development", "test"],
  }),
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: str({
    input: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  }),
  NEXT_PUBLIC_STRIPE_CURRENCY: str({
    input: process.env.NEXT_PUBLIC_STRIPE_CURRENCY,
  }),
  NEXT_PUBLIC_STRIPE_MIN_AMOUNT: num({
    default: 0,
    allowEmpty: false,
    input: process.env.NEXT_PUBLIC_STRIPE_MIN_AMOUNT,
  }),
  NEXT_PUBLIC_STRIPE_MAX_AMOUNT: num({
    default: 10000,
    allowEmpty: false,
    input: process.env.NEXT_PUBLIC_STRIPE_MAX_AMOUNT,
  }),
  NEXT_PUBLIC_USERNAME_PASSWORD_ENABLED: bool({
    input: process.env.NEXT_PUBLIC_USERNAME_PASSWORD_ENABLED,
  }),
  NEXT_PUBLIC_MICROSOFT_ENABLED: bool({
    input: process.env.NEXT_PUBLIC_MICROSOFT_ENABLED,
  }),
  NEXT_PUBLIC_GOOGLE_ENABLED: bool({
    input: process.env.NEXT_PUBLIC_GOOGLE_ENABLED,
  }),
})
