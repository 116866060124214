import { forwardRef, PropsWithoutRef } from "react"
import { useField } from "react-final-form"
import { theme } from "../styles/styles"

export interface LabeledTextFieldProps extends PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  /** Field name. */
  name: string
  /** Field label. */
  label: string
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "number"
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>
}

export const LabeledTextField = forwardRef<HTMLInputElement, LabeledTextFieldProps>(
  ({ name, label, outerProps, ...props }, ref) => {
    const {
      input,
      meta: { touched, error, submitError, submitting },
    } = useField(name, {
      parse: props.type === "number" ? Number : undefined,
    })

    const normalizedError = Array.isArray(error) ? error.join(", ") : error || submitError

    return (
      <div {...outerProps} style={{ margin: 0 }}>
        <input
          {...input}
          disabled={submitting}
          {...props}
          ref={ref}
          style={{
            fontWeight: 300,
            width: "100%",
            height: 40,
            marginTop: 6,
            marginBottom: 6,
            borderRadius: 8,
            borderWidth: 1,
            paddingLeft: 18,
            borderStyle: "solid",
            borderColor: theme.gray[300],
            boxShadow: "0 2px 0 rgb(0 0 0 / 2%)",
          }}
        />

        {touched && normalizedError && (
          <div role="alert" style={{ color: "red" }}>
            {normalizedError}
          </div>
        )}
      </div>
    )
  }
)

export default LabeledTextField
