const base = {
  fontSize: {
    xs: 12,
    sm: 14,
    md: 18,
    lg: 22,
    xl: 28,
  },
  spacing: {
    "1": "8px",
    "2": "12px",
    "3": "16px",
    "4": "24px",
    "5": "32px",
    "6": "48px",
  },
}

export const dark = {
  ...base,
}

export const light = {
  ...base,
  gray: {
    0: "#FFFFFF",
    300: "#F2F2F2",
    350: "#F8F8FA",
    400: "#e8e8e8",
    450: "#e3e8ee",
    500: "#a3acb9",
    550: "#8792a2",
    600: "#697386",
    700: "#4f566b",
    800: "#19212C",
    1000: "#000000",
  },
  tint: {
    400: "#4A52FF",
    500: "#5C62FA",
    900: "#151bbf",
  },
  red: {
    200: "#fde2e2",
    300: "#ff4d4f",
    400: "#ff4d4f",
    500: "#EC4E44",
    550: "#c41d23",
    600: "#c41d23",
  },
  green: {
    200: "#cbf4c9",
    300: "#52c41a",
    400: "#52c41a",
    500: "#34A853",
    550: "#0e6245",
    600: "#006999",
  },
  blue: {
    100: "#f3f3f3",
    300: "#5D6598",
    400: "#747797",
    600: "#006999",
  },
  background: {
    100: "#FFFFFF",
    125: "#f7fafc",
    150: "#f0f2f5",
    200: "#F2F2F2",
    300: "#EFEFF1",
    500: "#E3E2E7",
    1000: "#2D3748",
  },
}

export const theme = light
