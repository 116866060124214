import {
  MantineProvider,
  VariantColorsResolver,
  darken,
  defaultVariantColorsResolver,
  parseThemeColor,
  rem,
  rgba,
} from "@mantine/core"
import "@mantine/core/styles.css"
import "@mantine/core/styles.layer.css"
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import LoginForm from "app/auth/components/LoginForm"
import { ImpersonatingUserNotice } from "app/core/components/ImpersonatingUserNotice"
import {
  AppProps,
  AuthenticationError,
  AuthorizationError,
  ErrorComponent,
  ErrorFallbackProps,
  Head,
  useQueryErrorResetBoundary,
} from "blitz"
import { H } from "highlight.run"
import "mantine-datatable/styles.layer.css"
import { Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import "./layout.css"

if (process.env.NODE_ENV !== "development") {
  H.init("xdnr50e0")
}

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  })

  // Override some properties for variant
  if (parsedColor.isThemeColor && parsedColor.color === "lime" && input.variant === "filled") {
    return {
      ...defaultResolvedColors,
      color: "var(--mantine-color-black)",
      hoverColor: "var(--mantine-color-white)",
    }
  }

  // Completely override variant
  if (input.variant === "light") {
    return {
      background: rgba(parsedColor.value, 0.1),
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(1)} solid ${parsedColor.value}`,
      color: darken(parsedColor.value, 0.1),
    }
  }

  // Add new variants support
  if (input.variant === "danger") {
    return {
      background: "var(--mantine-color-red-9)",
      hover: "var(--mantine-color-red-8)",
      color: "var(--mantine-color-white)",
      border: "none",
    }
  }

  return defaultResolvedColors
}

export default function App({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <>
      <Head>
        <title>Evatir</title>
      </Head>
      <ErrorBoundary
        FallbackComponent={RootErrorFallback}
        onReset={useQueryErrorResetBoundary().reset}
      >
        <MantineProvider theme={{ variantColorResolver }}>
          <Notifications />
          <ModalsProvider modalProps={{ style: { zIndex: 2000 } }}>
            <Suspense fallback={"Loading..."}>
              <ImpersonatingUserNotice />
            </Suspense>
            <Suspense fallback={"Loading..."}>{getLayout(<Component {...pageProps} />)}</Suspense>
          </ModalsProvider>
        </MantineProvider>
      </ErrorBoundary>
    </>
  )
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    return <LoginForm onSuccess={resetErrorBoundary} />
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    )
  }
}
