import stopImpersonating from "app/auth/mutations/stopImpersonating"
import { invoke, queryClient, useSession } from "blitz"

export const ImpersonatingUserNotice = () => {
  const session = useSession()

  if (!session.impersonatingFromUserId) return null

  return (
    <div className="bg-yellow-400 px-2 py-1 text-center font-semibold">
      Currently impersonating user {session.staleName} ({session.userId}) as {session.orgRole}
      <button
        className="appearance-none bg-transparent text-black uppercase"
        onClick={async () => {
          await invoke(stopImpersonating, {})
          queryClient.clear()
        }}
      >
        Exit
      </button>
    </div>
  )
}
