import Layout, { Content, Footer } from "antd/lib/layout/layout"
import { theme } from "app/core/styles/styles"
import { EvatirLogo } from "app/pages"
import React from "react"
import { Version } from "version"

export const TAuthLayout = (props: { children: any }) => {
  return (
    <Layout style={{ padding: 50, backgroundColor: "#fff" }}>
      <Content style={{ display: "flex", placeContent: "center" }}>
        <div style={{ textAlign: "center", maxWidth: 500 }}>{props.children}</div>
      </Content>
      <Footer style={{ marginTop: 50 }}>
        <a href="https://evatir.com" rel="noreferrer" style={{ display: "block" }} target="_blank">
          <EvatirLogo style={{ color: theme.blue[600] }} />
        </a>
        <p style={{ opacity: 0, fontWeight: 300 }}> {Version.version}</p>
      </Footer>
    </Layout>
  )
}
